<template>
    <div class="p-5">
        <div class="border bg-white rounded-sm">
            <div class="px-5 py-3 border-b">
                <div class="flex justify-between items-center">
                    <div class="relative flex items-center">
                        <input v-model="search" type="text" class="border-b focus:outline-none w-96 py-1 pr-6" placeholder="Search customer name or code...">
                        <span v-show="!search" class="absolute right-0 text-gray-400"><i class="icon-search4"></i></span>
                        <a v-show="search" @click.prevent="search=''" href="#" class="text-red-500 absolute right-0"><i class="icon-cross3"></i></a>
                    </div>
                    <div class="flex items-center">
                        <div class="flex items-center mr-10">
                            <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                            <div class="flex items-center space-x-1">
                                <button @click="getData(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                                <button @click="getData(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                            </div>
                        </div>
                        <button class="h-7 w-7 bg-gray-100 border  -mr-px"><i class="icon-grid6"></i></button>
                        <button class="h-7 w-7 bg-gray-100 border"><i class="icon-list2"></i></button>
                    </div>
                </div>
            </div>
            <div class="bg-white">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th class="py-3 px-5 bg-teal-500 text-teal-50">Name</th>
                            <th class="py-3 px-5 bg-teal-500 text-teal-50">Group</th>
                            <th class="py-3 px-5 bg-teal-500 text-teal-50">Salesrespon</th>
                            <th class="py-3 px-5 bg-teal-500 text-teal-50">District</th>
                            <th class="py-3 px-5 bg-teal-500 text-teal-50">City</th>
                        </tr>
                    </thead>
                    <tbody class="divide-y">
                        <tr v-for="(customer , i) in customers" :key="i" :class="{'bg-gray-50': i%2 == 0}">
                            <td class="py-2.5 px-5">
                               <router-link :to="'/customers/detail/'+customer._id" class="text-teal-500 hover:text-teal-600">{{customer.displayName}}</router-link> 
                            </td>
                            <td class="py-2.5 px-5">{{customer.group}}</td>
                            <td class="py-2.5 px-5">{{customer.user}}</td>
                            <td class="py-2.5 px-5">{{customer.address.subdistrictName}}</td>
                            <td class="py-2.5 px-5">{{customer.address.cityName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
        <div class="text-right mt-5">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>
<script>
import Paginate from '../../components/paginate.vue'
import debounce from 'lodash.debounce'
import axios from 'axios'
import {moment} from '../../moment'
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../event-bus'
export default {
    components: {
        Paginate,
    },
    mixins:[BreadcrumbManager],
    data () {
        return {
            isLoading: false,
            search: '',
            customers: '',
            pages: '',
            searchData: '',
            filterGroup: 'total customer',
            totalItems: '',
        }
    },
    created () {
        EventBus.$on('onClick', this.createCustomer)
        this.setBreadcrumbs([
            {text: 'Customers'}
        ])
        this.setButtonText('CREATE')
        this.setDiscard(false)

        this.searchData = debounce(() => {
            this.getData()
        }, 250)
    },
    destroyed () {
       EventBus.$off('onClick', this.createCustomer)
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData (i) {
            this.isLoading = true
            window.scrollTo(0,0)
            axios.get('/customers/', {
                params: {
                    page: i,
                    search: this.search
                }
            })
            .then(res => {
                this.isLoading = false
                this.customers = res.data.data
                this.pages = res.data.pages
                this.totalItems = res.data.totalItems
            })
        },
        getDate (d) {
            return moment(d)
        },
        createCustomer () {
            const path = '/customers/new'
            if(this.$route.path != path) this.$router.push('/customers/new')
        }
    },
    watch: {
        search () {
            this.searchData()
        }
    },  
}
</script>
